import React from 'react';
import PropTypes from 'prop-types';

import propTypes from '../../../core/prop-types';

import ChapterTile from './chapter-tile';
import Back from '../../../components/back';

const ChapterList = props => {
  const {
    nodes,
    slugs,
    fixedHeight,
    openExercise,
    module,
    method,
  } = props;

  return (
    <section>
      <div className="container">
        <Back href={`/leerling/${slugs.publishingHouse}/${slugs.subject}/${slugs.method}`} />
        <div className="bao-llnstudio-chapter">
          <div className="row">
            {nodes && nodes.map(node => (
              <ChapterTile
                fixedHeight={fixedHeight}
                key={node.id}
                color={node.primaryColor || module.primaryColor || method.primaryColor}
                image={node.image}
                noNestedNodes={!node.nodes || node.nodes.length === 0}
                title={node.title}
                description={node.description}
                mediaLinks={node.mediaLinks}
                module={module}
                slug={node.slug &&
                  '/leerling' + // concatenation for readability
                  `/${slugs.publishingHouse}` +
                  `/${slugs.subject}` +
                  `/${slugs.method}` +
                  `/${slugs.module}` +
                  `/${node.slug}`
                }
                openExercise={openExercise}
              />
            ))}
          </div >
        </div>
      </div>
    </section >
  );
};

ChapterList.propTypes = {
  nodes: PropTypes.arrayOf(propTypes.node),
  slugs: PropTypes.object,
  fixedHeight: PropTypes.object.isRequired,
  openExercise: PropTypes.func.isRequired,
  module: PropTypes.object,
  method: PropTypes.object
};

ChapterList.defaultProps = {
  slugs: {},
  nodes: null,
  module: {},
  method: {},
};

export default ChapterList;
