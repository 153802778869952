import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ModulePicker = styled.div`
    .bao-llnstudio-modulepicker__year-select-method--bullet {
      border-color: ${props => props.color} !important;
      color: ${props => props.color} !important;
    }

    &:hover {
        .bao-llnstudio-modulepicker__year-select-method--bullet {
          background: ${props => props.color} !important;
          color: #fff !important;
        }
    }
`;

const ModuleYear = props => {
  const {
    year,
    cover,
    color,
    showCover,
    slug,
    alternativeStudentSiteUrl,
  } = props;

  return (
    <ModulePicker
      color={color}
      tabIndex="0"
      role="button"
      className="bao-llnstudio-modulepicker__year-select-method col-6 col-sm-4 col-lg-2"
    >
      {alternativeStudentSiteUrl ?
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="bao-llnstudio-modulepicker__year-select-method--bullet alternative--url"
          href={alternativeStudentSiteUrl}
        >
          {year}
        </a> :
        <Link
          className="bao-llnstudio-modulepicker__year-select-method--bullet"
          to={slug}
        >
          {year}
        </Link>
      }

      <div className="clearfix" />

      {cover &&
        <div className="cover-image-preloader">
          <img src={cover} />
        </div>
      }

      {showCover && cover &&
        <img className="bao-llnstudio-modulepicker__year-select-method--img" src={cover} />
      }
    </ModulePicker>
  );
};

ModuleYear.propTypes = {
  year: PropTypes.string,
  cover: PropTypes.string,
  color: PropTypes.string,
  slug: PropTypes.string,
  showCover: PropTypes.bool,
  alternativeStudentSiteUrl: PropTypes.string,
};

ModuleYear.defaultProps = {
  year: '0',
  color: '#4DACC6',
  slug: '/leerling',
  cover: null,
  showCover: false,
  alternativeStudentSiteUrl: undefined,
};

export default ModuleYear;
