import React from 'react';
import PropTypes from 'prop-types';

import propTypes from '../../../core/prop-types';

import LessonTile from './lesson-tile';
import Back from '../../../components/back';

const LessonList = props => {
  const {
    chapter,
    fixedHeight,
    nodes,
    slugs,
    method,
    openExercise,
    module
  } = props;

  return (
    <section>
      <div className="container">
        <Back
          color={chapter.primaryColor || module.primaryColor || method.primaryColor}
          title={chapter.title}
          href={`/leerling/${slugs.publishingHouse}/${slugs.subject}/${slugs.method}/${slugs.module}`}
        />

        <div className="bao-llnstudio-lesson">
          <div className="row">
            {nodes && nodes.map(node => (
              <LessonTile
                display={method.display}
                color={node.primaryColor || chapter.primaryColor || module.primaryColor || method.primaryColor}
                key={node.id}
                image={node.image}
                title={node.title}
                prefix={node.prefix}
                mediaLinks={node.mediaLinks}
                fixedHeight={fixedHeight}
                openExercise={openExercise}
                module={module}
              />
            ))}
          </div >
        </div>
      </div>
    </section >
  );
};

LessonList.propTypes = {
  nodes: PropTypes.arrayOf(propTypes.node).isRequired,
  slugs: PropTypes.object.isRequired,
  fixedHeight: PropTypes.bool.isRequired,
  chapter: propTypes.node.isRequired,
  openExercise: PropTypes.func.isRequired,
  method: PropTypes.object,
  module: PropTypes.object,
};

LessonList.defaultProps = {
  module: {},
  method: {},
};

export default LessonList;
