import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Background = styled.div`
  background-color: ${props => props.color && `${props.color} !important`};
`;

const Back = props => {
  const {
    href,
    color,
    title,
  } = props;

  return (
    <Background
      color={title ? color : undefined}
      className={title
        ? 'bao-llnstudio-return bao-llnstudio-return-lessonlevel'
        : 'bao-llnstudio-return'
      }
    >
      <div className="row">
        <div className="col-8">
          <span className="bao-llnstudio-return__theme-title">
            {title}
          </span>
        </div>

        <div className="col-4">
          <Link
            to={href}
            className="bao-llnstudio-return__previous"
          >
            <i className="icon-bao-llnstudio-arrow-left" />
            <span>terug</span>
          </Link>
        </div>
      </div>
    </Background>
  );
};

Back.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
};

Back.defaultProps = {
  title: undefined,
  color: '#4DACC6',
};

export default Back;
