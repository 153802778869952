import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MethodList from './components/method-list';
import Header from '../../components/header';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Title from '../../components/title';

import propTypes from '../../core/prop-types';

import { fetchEntitiesIfRequired } from '../../actions/entities';

export class MethodsPage extends React.Component {
  componentDidMount() {
    this.props.fetchEntities();
  }

  render() {
    const { subjects, methods, isReady } = this.props;
    return (
      <div className="content-container">
        <Header />

        <Menu />

        <Title text="oefenen?" subText="Wat wil je" />

        <section id="content">
          {isReady && <MethodList methods={methods} subjects={subjects} />}
        </section >

        <Footer />
      </div >
    );
  }
}

MethodsPage.propTypes = {
  fetchEntities: PropTypes.func.isRequired,
  methods: PropTypes.arrayOf(propTypes.method),
  subjects: propTypes.subject,
  isReady: PropTypes.bool,
};

MethodsPage.defaultProps = {
  methods: [],
  subjects: {},
  isReady: false,
};

const mapStateToProps = state => ({
  methods: Object.values(state.entities.methods),
  subjects: state.entities.subjects,
  isReady: state.entities.metaData.receivedAt && !state.entities.metaData.isPending
});

const mapDispatchToProps = dispatch => () => ({
  fetchEntities: () => dispatch(fetchEntitiesIfRequired()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MethodsPage);
