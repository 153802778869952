import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  slug: PropTypes.string,
  modules: PropTypes.arrayOf(PropTypes.string),
  subject: PropTypes.string,
});
