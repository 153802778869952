import React from 'react';

import Modal from './modal';
import HelpContent from './help-content';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  render() {
    const { showModal } = this.state;

    return (
      <section id="header">
        <div className="bao-llnstudio-header container-fluid">
          <div className="bao-llnstudio-header__exercises mr-auto">
            <img src="/assets/img/logo-pelckmans-2021.svg" />
            <span>Oefeningen basisonderwijs</span>
          </div>
          <a
            tabIndex="0"
            role="button"
            className="bao-llnstudio-header__right"
            onClick={() => {
              this.setState({
                showModal: !showModal,
              });
            }}
          >
            <span>
              Hulp&nbsp;
              <i className="icon-bao-llnstudio-help" />
            </span>
          </a>
        </div>

        {showModal && (
          <Modal
            onClick={() => {
              this.setState({
                showModal: !showModal,
              });
            }}
            className="modal-lg"
          >
            <HelpContent />
          </Modal>
        )}
      </section>
    );
  }
}

export default Header;
