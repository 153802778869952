import frMessages from './messages/fr';
import nlMessages from './messages/nl';

const DEFAULT_LOCALE = 'nl';
// const LANGS = ['fr', 'nl'];

export default {
  messages: {
    nl: nlMessages,
    fr: frMessages,
  },
  getCurrentLangKey: () => (
    // const locale = localStorage.getItem('locale');
    // if (!locale) localStorage.setItem('locale', DEFAULT_LOCALE);

    // const currentLocale = LANGS.find(lang => lang.startsWith(locale));
    // return currentLocale || DEFAULT_LOCALE;
    DEFAULT_LOCALE
  ),
};
