import mediaLink from './media-link';
import subject from './subject';
import publishingHouse from './publishing-house';
import node from './node';
import toc from './toc';
import module from './module';
import method from './method';

export default {
  mediaLink,
  subject,
  publishingHouse,
  node,
  toc,
  module,
  method,
};
