import * as Actions from '../action-types/entities';

const initialState = {
  metaData: {
    isPending: false,
  },
  methods: {},
  subjects: {},
  modules: {},
  tocs: {},
  nodes: {},
  mediaLinks: {},
};

// eslint-disable-next-line max-statements
export default function dataReducer(state = initialState, action) {
  switch (action && action.type) {
    case Actions.FETCH_ENTITIES_REQUESTED: {
      return {
        ...state,
        metaData: {
          isPending: true,
        },
      };
    }
    case Actions.FETCH_ENTITIES_RECEIVED: {
      const modules = {};

      // turn years into string
      if (action.payload.entities.modules) {
        Object.keys(action.payload.entities.modules).forEach(id => {
          modules[id] = action.payload.entities.modules[id];

          const years = modules[id].years;
          if (years && years.length > 0) modules[id].years = years.sort()[0];
          // earliest year
          else modules[id].years = '0';
        });
      }

      const entities = Object.assign({}, action.payload.entities, { modules });

      return {
        ...state,
        ...entities,
        metaData: {
          isPending: false,
          receivedAt: new Date(),
        },
      };
    }
    case Actions.FETCH_ENTITIES_FAILED: {
      return {
        ...state,
        metaData: {
          isPending: false,
        },
      };
    }
    default:
      return state;
  }
}
