import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl'
import { useCookies } from 'react-cookie'

const CookieNotifier = () => {
  const [cookies, setCookies] = useCookies(['cl'])
  const [accepted, setAccepted] = useState(cookies.cl);

  const trackingId = ANALYTICS_KEY || 'UA-81304517-2';

  const acceptCookies = () => {
    setCookies('cl', true, { secure: true, sameSite: 'strict' })
    setAccepted(true);
  }

  // Recommended solution for disabling Universal Analytics (gtag.js)
  useEffect(() => {
    window[`ga-disable-${trackingId}`] = !accepted
  });

  return (!accepted &&
    <div className="bao-llnstudio-cookienotifier">
      <div className="bao-llnstudio-cookienotifier__icon">
        <i className="icon-bao-llnstudio-cookie" />
      </div>

      <div className="bao-llnstudio-cookienotifier__text">
        <FormattedMessage id="cookies_message" />
        <a className="bao-llnstudio-cookienotifier__link" href={`${PORTAAL_URL}/privacybeleid#cookies`} target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="cookies_link" />
        </a>
      </div>

      <button
        type="button"
        className="bao-llnstudio-btn bao-llnstudio-btn--practice-main"
        onClick={() => acceptCookies()}
      >
        <FormattedMessage id="cookies_cta" />
      </button>

    </div>
  );
}

export default CookieNotifier;
