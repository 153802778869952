import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import propTypes from '../../../core/prop-types';

import ModuleYear from './module-year';
import Back from '../../../components/back';

export const MethodTitle = styled.h5`
      color: ${props => props.color || '#5AAF2D'} !important;
`;

class ModuleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCover: false,
    };
  }

  render() {
    const { method, subject, modules } = this.props;
    const { showCover } = this.state;

    return (
      <section id="modulepicker" >
        <div className="container">
          <Back href="/leerling" />

          <div className="bao-llnstudio-modulepicker">
            <div className="row">
              <div className="bao-llnstudio-modulepicker__method col-12 col-sm-12 col-md-3">
                <img className="bao-llnstudio-modulepicker__method-img" src={method.logo} alt={method.name} />
                <MethodTitle color={method.primaryColor} className="bao-llnstudio-modulepicker__method-title">{subject.name}</MethodTitle>
              </div>

              <div className="bao-llnstudio-modulepicker__year col-12 col-sm-12 col-md-9">
                <div className="row">
                  {modules && modules.map(mod => (
                    <ModuleYear
                      key={mod.id}
                      color={mod.primaryColor || method.primaryColor}
                      year={mod.years}
                      cover={mod.cover}
                      showCover={showCover}
                      slug={`/leerling/${subject.publishingHouse.slug}/${subject.slug}/${method.slug}/${mod.slug}`}
                      alternativeStudentSiteUrl={mod.alternativeStudentSiteUrl}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bao-llnstudio-toggler">
            <a
              className="show"
              tabIndex="0"
              role="button"
              onClick={() => this.setState(prevState => ({ showCover: !prevState.showCover }))}
            >
              <span>{showCover ? 'verberg' : 'toon'} de omslagfoto van het boek</span>
              <i className={showCover ? 'icon-bao-llnstudio-arrow-up' : 'icon-bao-llnstudio-arrow-down'} />
            </a>
          </div>
        </div>
      </section >
    );
  }
}

ModuleList.propTypes = {
  method: propTypes.method,
  subject: propTypes.subject,
  modules: PropTypes.arrayOf(propTypes.module),
};

ModuleList.defaultProps = {
  method: {},
  subject: {
    publishingHouse: {},
  },
  modules: [],
};

export default ModuleList;
