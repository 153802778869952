import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const HomeLink = ({ displayText }) => (
  <Link to="/" className={`${displayText ? 'bao-llnstudio-menu__nav-menu' : 'bao-llnstudio-menu__nav-home'}`}>
    <span>
      <i className="icon-bao-llnstudio-home" />
      {displayText &&
        'Startscherm'
      }
    </span>
  </Link>
);

HomeLink.propTypes = {
  displayText: PropTypes.bool,
};

HomeLink.defaultProps = {
  displayText: false,
};

export default HomeLink;
