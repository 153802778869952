import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Title from '../../components/title';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import ModuleList from './components/module-list';

import propTypes from '../../core/prop-types';

import { fetchEntitiesIfRequired } from '../../actions/entities';

export class ModulesPage extends React.Component {
  componentDidMount() {
    this.props.fetchEntities();
  }

  render() {
    const { method, subject, modules } = this.props;

    return (
      <div className="content-container">
        <Menu color={method.primaryColor} image={method.logo} methodId={method.id} />

        <Title text="Kies een leerjaar" color={method.primaryColor} />

        <section id="content">
          <ModuleList method={method} subject={subject} modules={modules} />
        </section>

        <Footer />
      </div>
    );
  }
}

ModulesPage.propTypes = {
  fetchEntities: PropTypes.func.isRequired,
  method: propTypes.method,
  subject: propTypes.subject,
  modules: PropTypes.arrayOf(propTypes.module),
};

ModulesPage.defaultProps = {
  method: {},
  subject: {
    publishingHouse: {}
  },
  modules: [],
};

const mapStateToProps = (state, props) => {
  const { match: { params } } = props;

  // As there is no guaranteed uniqueness of slug we have to search deep
  const subjects = Object
    .values(state.entities.subjects)
    .filter(s => s.slug === params.subject && (s.publishingHouse && s.publishingHouse.slug === params.publishingHouse));

  const method = Object
    .values(state.entities.methods)
    .filter(m => m.slug === params.method)
    .find(m => subjects.map(x => x.id).includes(m.subject));

  if (!method) return {};

  const subject = state.entities.subjects[method.subject];

  const modules = method.modules
    .map(id => state.entities.modules[id])
    .sort((a, b) => {
      const yearA = +a.years[0];
      const yearB = +b.years[0];

      return yearA - yearB;
    });

  return {
    method,
    subject,
    modules,
  };
};

const mapDispatchToProps = dispatch => () => ({
  fetchEntities: () => dispatch(fetchEntitiesIfRequired()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModulesPage);
