import React from 'react';
import styled from 'styled-components';
import MethodLink from './method-link';

import propTypes from '../../../core/prop-types';

export const LinkContainer = styled.div`
    a{
      &:before {
            background-color: ${props => (props.color ? props.color : '#4DACC6')};
        }
    }
`;

export const Title = styled.h5`
    color: ${props => (props.color ? props.color : '#5AAF2D')};
`;

const MethodTile = ({ method, subject }) => (
  <LinkContainer
    color={method.primaryColor}
    className="col-12 col-sm-6 col-md-4 col-lg-4 bao-llnstudio-methodpicker__tile"
  >
    <MethodLink method={method} subject={subject}>
      <img
        className="bao-llnstudio-methodpicker__tile-img"
        src={method.logo}
        alt={method.name || 'Onbekend'}
      />
      <Title
        className="bao-llnstudio-methodpicker__tile-title"
        color={method.primaryColor}
      >
        {subject.name || 'Onbekend'}
      </Title>
    </MethodLink>
  </LinkContainer>
);

MethodTile.propTypes = {
  method: propTypes.method,
  subject: propTypes.subject,
};

MethodTile.defaultProps = {
  method: {},
  subject: {
    publishingHouse: {},
  },
};

export default MethodTile;
