import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fadeOut: false,
    };
  }

  render() {
    const { onClick, children, className } = this.props;

    return (
      <div
        className={`bao-llnstudio-alert${this.state.fadeOut ? ' bao-llnstudio-alert--fadeOut' : ''}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        onClick={() => {
          this.setState({ fadeOut: true });
          setTimeout(onClick, 250);
        }}
      >
        <div className={`modal-dialog modal-dialog-centered bao-llnstudio-alert__dialog ${className}`} role="document">
          <div className="modal-content bao-llnstudio-alert__content">
            <div className="modal-content bao-llnstudio-alert__content">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
