import React from 'react';
import { Link } from 'react-router-dom';
import { node } from 'prop-types';

import propTypes from '../../../core/prop-types';

const MethodLink = ({ method, subject, children }) => {
  return (
    method.alternativeStudentSiteUrl ?
      <a
        tabIndex="0"
        target="_blank"
        rel="noopener noreferrer"
        href={method.alternativeStudentSiteUrl}
      >
        {children}
      </a>
      :
      <Link
        tabIndex="0"
        role="button"
        to={`/leerling/${(subject.publishingHouse || {}).slug}/${subject.slug}/${method.slug}`}
      >
        {children}
      </Link>
  )
}

MethodLink.propTypes = {
  method: propTypes.method.isRequired,
  subject: propTypes.subject.isRequired,
  children: node.isRequired
};


export default MethodLink;
