import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import nl from 'react-intl/locale-data/nl';
import fr from 'react-intl/locale-data/fr';

import intl from './intl';
import { init, RouteTracker } from './components/google-analytics';
import CookieNotifier from './components/cookie-notifier';

import Lessons from './modules/lessons';
import Chapters from './modules/chapters';
import Modules from './modules/modules';
import Splitter from './modules/splitter';
import Methods from './modules/methods';

addLocaleData([...nl, ...fr]);

const App = () => {
  const locale = intl.getCurrentLangKey();

  return (
    <IntlProvider locale={locale} messages={intl.messages[locale]}>

      <BrowserRouter>
        <div id="router-container">
          {init() && <RouteTracker />}
          <Switch>
            <Route path="/leerling/:publishingHouse/:subject/:method/:module/:node" component={Lessons} />
            <Route path="/leerling/:publishingHouse/:subject/:method/:module" component={Chapters} />
            <Route path="/leerling/:publishingHouse/:subject/:method" component={Modules} />
            <Route path="/leerling" component={Methods} />
            <Route component={Splitter} />
          </Switch>
          <CookieNotifier />
        </div>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default App;
