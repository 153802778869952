import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';

import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

class GoogleAnalytics extends Component {
  componentDidMount() {
    const { location: { pathname, search } } = this.props;
    this.logPageChange(
      pathname,
      search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    const { options } = this.props;

    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...options,
    });
    ReactGA.pageview(page)
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: RouterPropTypes.location.isRequired,
  options: PropTypes.object,
};

GoogleAnalytics.defaultProps = {
  options: {},
};

export const RouteTracker = () => <Route component={GoogleAnalytics} />;

export const init = options => {
  const isProduction = process.env.NODE_ENV === 'production';
  const debugMode = !isProduction;
  const trackingId = ANALYTICS_KEY || 'UA-81304517-2';

  if (trackingId) {
    ReactGA.initialize(
      trackingId, {
      debug: debugMode,
      ...options
    }
    );
  }

  return window.ga;
};

export default {
  RouteTracker,
  init,
};
