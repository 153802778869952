import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  color: PropTypes.string,
  logo: PropTypes.string,
  slug: PropTypes.string,
  year: PropTypes.string,
  toc: PropTypes.string,
});
