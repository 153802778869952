import React from 'react';
import PropTypes from 'prop-types';

class Exercise extends React.Component {
  render() {
    const { href } = this.props;

    return (
      <div id="exercise" className="content-container">
        <section id="content">
          <div className="container-fluid">
            <div className="bao-llnstudio-practices">
              <iframe id="check-tp-cookies" title="exercise" src={href} frameBorder="0" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Exercise.propTypes = {
  href: PropTypes.string,
};

Exercise.defaultProps = {
  href: '',
};

export default Exercise;
