import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import propTypes from '../core/prop-types';

import HomeLink from './links/home-link';

import Modal from './modal';
import HelpContent from './help-content';

export const SectionMenu = styled.section`
  background-color: ${props => props.color && `${props.color} !important`};
`;

export const Year = styled.span`
  background-color: ${props => props.color && `${props.color} !important`};
  border-color: ${props => props.color && `${props.color} !important`};
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelp: false,
      showModal: false,
      showWarning: false,
    };
  }

  showHelpModal() {
    this.setState({
      showHelp: true,
      showModal: true,
      showWarning: false,
    })
  }

  closeModal() {
    this.setState({
      showHelp: false,
      showModal: false,
      showWarning: false,
    })
  }

  render() {
    const {
      color,
      image,
      moduleColor,
      years,
      methodId,
      modulesSlug,
      lesson,
      chapterTitle,
      closeExercise,
      restartExercise,
      nextExercise,
    } = this.props;

    return (
      <SectionMenu id="menu" color={color}>
        {!methodId ? (
          <div className="bao-llnstudio-menu">
            <div className="container-fluid">
              <div className="bao-llnstudio-menu__nav">
                <HomeLink displayText />
              </div>
            </div>
          </div>
        )
          : (
            <div>
              {!lesson ? (
                <div className="bao-llnstudio-menu">
                  <HomeLink />

                  <div className="container">
                    <div className="bao-llnstudio-menu__nav">
                      <ul>
                        <li className="bao-llnstudio-menu__nav-method">
                          <Link to="/leerling">
                            <img src={image} />
                          </Link>
                        </li>

                        {years && (
                          <li className="bao-llnstudio-menu__nav-module">
                            <Link to={modulesSlug} className="bao-llnstudio-modulepicker__year-select-method">
                              <Year
                                color={moduleColor || color}
                                className="bao-llnstudio-modulepicker__year-select-method--bullet"
                              >
                                {years}
                              </Year>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>

                  <a
                    className="bao-llnstudio-menu__nav-help"
                    tabIndex="0"
                    role="button"
                    onClick={() => this.showHelpModal()}
                  >
                    <i className="icon-bao-llnstudio-help" />
                  </a>
                </div>
              )
                : (
                  <div className="bao-llnstudio-menu">
                    <div className="container-fluid">
                      <div className="bao-llnstudio-menu__nav">
                        <ul>
                          <li className="bao-llnstudio-menu__nav-method">
                            <Link to="/leerling">
                              <img src={image} />
                            </Link>
                          </li>

                          {years && (
                            <li className="bao-llnstudio-menu__nav-module">
                              <Link to={modulesSlug} className="bao-llnstudio-modulepicker__year-select-method">
                                <Year
                                  color={moduleColor}
                                  className="bao-llnstudio-modulepicker__year-select-method--bullet"
                                >
                                  {years}
                                </Year>
                              </Link>
                            </li>
                          )}
                          <li className="bao-llnstudio-menu__nav-lesson">
                            <small>{chapterTitle}</small>
                            <strong>
                              {lesson.prefix} {lesson.prefix && lesson.title && '-'} {lesson.title}
                            </strong>
                          </li>
                          <li className="bao-llnstudio-menu__nav-close ml-auto">
                            <a
                              tabIndex="0"
                              role="button"
                              onClick={() => {
                                this.setState({
                                  showHelp: false,
                                  showModal: true,
                                  showWarning: true,
                                });
                              }}
                            >
                              <span>Oefenreeks afsluiten</span>
                              <i className="icon-bao-llnstudio-close" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}

        {this.state.showModal && (
          <Modal
            onClick={() => {
              this.closeModal();
            }}
            className={this.state.showHelp ? 'modal-lg' : ''}
          >
            {this.state.showHelp && <HelpContent />}

            {this.state.showWarning &&
              <div>
                <div className="modal-header bao-llnstudio-alert__content-header">
                  <h5 className="modal-title">Opgelet!</h5>
                  <p>Als je deze oefeningen afsluit, zal je volgende keer helemaal opnieuw moeten beginnen.</p>
                </div>
                <div className="modal-body bao-llnstudio-alert__content-body">
                  <ul>
                    <li>
                      <button
                        type="button"
                        className="bao-llnstudio-btn"
                        onClick={() => this.closeModal()}
                      >
                        reeks afwerken <i className="icon-bao-llnstudio-arrow-right" />
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="bao-llnstudio-btn bao-llnstudio-btn--practice-main"
                        onClick={() => restartExercise()}
                      >
                        opnieuw beginnen <i className="icon-bao-llnstudio-restart" />
                      </button>
                    </li>
                    {nextExercise &&
                      <li>
                        <button
                          type="button"
                          className="bao-llnstudio-btn bao-llnstudio-btn--practice-alt1"
                          onClick={() => nextExercise()}
                        >
                          meer oefenen hierop
                        </button>
                      </li>
                    }
                    <li>
                      <button
                        type="button"
                        className="bao-llnstudio-btn bao-llnstudio-btn--practice-alt2"
                        onClick={() => closeExercise()}
                      >
                        toch sluiten <i className="icon-bao-llnstudio-close" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            }
          </Modal>
        )}
      </SectionMenu>
    );
  }
}

Menu.propTypes = {
  methodId: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
  moduleColor: PropTypes.string,
  years: PropTypes.string,
  modulesSlug: PropTypes.string,
  lesson: propTypes.node,
  chapterTitle: PropTypes.string,
  closeExercise: PropTypes.func,
  restartExercise: PropTypes.func,
  nextExercise: PropTypes.func,
};

Menu.defaultProps = {
  color: null,
  image: null,
  moduleColor: null,
  years: null,
  lesson: null,
  chapterTitle: null,
  methodId: undefined,
  modulesSlug: '/leerling',
  closeExercise: null,
  restartExercise: null,
  nextExercise: null,
};

export default Menu;
