import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const SplitterPage = ({ history }) => {
  return (
    <div id="splitter" className="content-container">
      <section id="header">
        <div className="bao-llnstudio-header container-fluid">
          <a href="https://www.pelckmans.be/" className="mr-auto" target="_blank" rel="noopener noreferrer">
            <img src="./assets/img/logo-pelckmans-footer-2021.svg" height="42" alt="" />
          </a>
          <div className="bao-llnstudio-header__right bao-llnstudio-header__right--bao">
            <span>
              <FormattedMessage id="bao" />
            </span>
          </div>
        </div>
      </section>

      <section id="content">
        <div className="container">
          <div className="row">
            <div className="pm-splitter-hello col-12">
              <FormattedMessage id="who_are_you" />
            </div>
            <div className="pm-splitter col-12">
              <div className="row">
                <div className="pm-splitter__student col-12 col-sm-12 col-md-6">
                  <button
                    type="button"
                    className="p-btn"
                    onClick={() => {
                      history.push('/leerling');
                    }}
                  >
                    <FormattedMessage id="student" />
                  </button>
                  <div className="pm-splitter__list">
                    <h5>
                      <FormattedMessage id="exercises" />
                    </h5>
                    <ul>
                      <li>extra oefeningen</li>
                      <li>bij lessen uit je boek</li>
                      <li>in de klas of thuis</li>
                    </ul>
                  </div>
                </div>
                <div className="pm-splitter__teacher col-12 col-sm-12 col-md-6">
                  <button
                    type="button"
                    className="p-btn"
                    onClick={() => {
                      window.location.assign(PORTAAL_URL);
                    }}
                  >
                    <FormattedMessage id="teacher" />
                  </button>
                  <div className="pm-splitter__list">
                    <h5>
                      <FormattedMessage id="teacher_platform" />
                    </h5>
                    <ul>
                      <li>activeer je code</li>
                      <li>raadpleeg het digitaal materiaal</li>
                      <li>bekijk ons aanbod</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="footer">
        <footer className="pm-splitter-footer container-fluid">
          <div className="text-center pm-splitter-footer__copyright">
            Copyright <span>©</span>
            &nbsp; 2022 - <FormattedMessage id="part_of" />
            <a href="http://www.pelckmansuitgevers.be/" target="_blank" rel="noopener noreferrer">
              <span>Pelckmans Uitgevers</span>
            </a>
            .
          </div>
          <div className="text-center">
            <a href={`${PORTAAL_URL}/algemene-voorwaarden`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="conditions" />
            </a>
            |
            <a href={`${PORTAAL_URL}/privacybeleid#privacy-policy`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy" />
            </a>
            |
            <a href={`${PORTAAL_URL}/privacybeleid#cookies`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="cookies" />
            </a>
          </div>
        </footer>
      </section>
    </div>
  );
};

SplitterPage.propTypes = {
  history: PropTypes.object,
};

SplitterPage.defaultProps = {
  history: {},
};

export default SplitterPage;
