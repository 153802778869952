export default {
  bao: 'Basisonderwijs',
  who_are_you: 'Hallo, wie ben je?',
  student: 'leerling',
  exercises: 'Oefeningen',
  teacher: 'leerkracht',
  teacher_platform: 'Leerkrachtenplatform',
  privacy: ' Privacy ',
  conditions: 'Algemene voorwaarden ',
  part_of: 'Pelckmans maakt deel uit van ',
  cookies: ' Cookies',
  cookies_message: 'Onze site maakt gebruik van cookies. Deze zijn noodzakelijk zodat je oefeningen kunt maken én we gebruiken deze ook om onze site te verbeteren. Als je verder gaat op deze site, dan gaan we er van uit dat je hiermee akkoord gaat. ',
  cookies_link: 'Meer informatie.',
  cookies_cta: 'Akkoord'
};
