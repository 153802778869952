import React from 'react';

const Helpcontent = () => (
  <div>
    <div className="modal-header bao-llnstudio-alert__content-header">
      <h4 className="modal-title">
        <i className="icon-bao-llnstudio-help" /> Hoe werkt het?
      </h4>
    </div>
    <div className="bao-llnstudio-modalbody">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 bao-llnstudio-modalcol">
          <h3 className="bao-llnstudio-modal-h--green">Hoe start je een oefenreeks?</h3>
          <ol className="bao-llnstudio-list--numberedimportant">
            <li>
              Kies het <span>boek</span> dat je in de klas gebruikt.
            </li>
            <li>
              Kies het <span>hoofdstuk</span> en de les waarvoor je wilt oefenen.
            </li>
            <li>
              Klik op de ‘<span>Oefen</span>’-knop: het oefenscherm verschijnt.
            </li>
          </ol>
          <h3 className="bao-llnstudio-modal-h--green">Hoe maak je oefeningen?</h3>
          <ol className="bao-llnstudio-list--numberedimportant">
            <li>
              <span>Lees</span> de vraag en bekijk wat je precies moet doen.
            </li>
            <li>Vul de oefening in.</li>
            <li>
              Klik op de knop <span>Verbeter</span>. Je krijgt te zien of je antwoord juist of fout is.
            </li>
            <li>
              Je kunt je antwoord nog <span>veranderen</span>. Als je het antwoord echt niet weet, dan kun je de{' '}
              <span>oplossing</span> opvragen.
            </li>
            <li>
              Op het einde krijg je jouw <span>resultaat</span> te zien. Sluit daarna de oefenreeks of kies bij het afsluiten voor <span>opnieuw</span>.
            </li>
          </ol>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 bao-llnstudio-modalcol">
          <p className="bao-llnstudio-alertbox">
            De oefeningen zijn vrij toegankelijk.<br />
            Je hebt dus geen login of wachtwoord nodig.
          </p>
          <h3 className="bao-llnstudio-modal-h--red">Opgelet!</h3>
          <p>De resultaten die je behaalt, worden niet opgeslagen!</p>
          <p>Toon daarom aan het einde van je oefenreeks je resultaten meteen aan je leerkracht of je ouders.</p>
          <p>
            Als je tijdens het oefenen de oefeningenreeks stopt of het scherm sluit, dan ben je al je antwoorden kwijt. Je
            zal de oefeningenreeks opnieuw moeten maken om een resultaat te behalen.
          </p>
          <h3 className="bao-llnstudio-modal-h--blue">Ben je een leerkracht?</h3>
          <p>
            Leerkrachten kunnen de oefeningen ook bereiken via het leerkrachtenplatform&nbsp;
            <a href={ PORTAAL_URL }>{ PORTAAL_URL }</a>. Als
            leerkracht kun je daar ook terecht voor meer hulp en een aantal vaak gestelde vragen.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Helpcontent;
