import React from 'react';
import PropTypes from 'prop-types';

const getClassNameForDifficulty = (difficulty) => {
  switch (difficulty) {
    case 1:
      return 'bao-llnstudio-btn--practice-alt2'; // red
    case 2:
      return 'bao-llnstudio-btn--practice-alt1'; // orange
    default:
      return 'bao-llnstudio-btn--practice-main'; // blue
  }
};

const ExerciseButton = ({ difficulty, onClick }) => (
  <button
    type="button"
    className={`bao-llnstudio-btn ${getClassNameForDifficulty(difficulty)}`}
    onClick={onClick}
  >
    oefen
  </button >
);

ExerciseButton.propTypes = {
  difficulty: PropTypes.number,
  onClick: PropTypes.func,
};

ExerciseButton.defaultProps = {
  difficulty: 0,
  onClick: () => { },
};

export default ExerciseButton;
