import PropTypes from 'prop-types';

const node = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  slug: PropTypes.string,
  prefix: PropTypes.string,
  mediaLinks: PropTypes.arrayOf(PropTypes.any),
  nodes: PropTypes.arrayOf(PropTypes.string),
});

export default node;
