import PropTypes from 'prop-types';

import publishingHouse from './publishing-house';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  publishingHouse,
});
