import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import styled from 'styled-components';

export const TopBg = styled.div`
  svg {
    g {
      fill: ${props => props.color};
    }
  }
`;

const Title = ({ text, subText, color }) => (
  <section id="title">
    <TopBg color={color} className="bao-llnstudio-page__topbg">
      <ReactSVG path="/assets/img/bao-llnstudio-bg-nav.svg" />
      <div className="bao-llnstudio-page__title">
        {subText && <span className="sub">{subText}</span>}
        <span>{text}</span>
      </div>
    </TopBg>
  </section >
);

Title.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  color: PropTypes.string,
};

Title.defaultProps = {
  text: '',
  subText: null,
  color: '#4DACC6',
};

export default Title;
