import React from 'react';

const Footer = () => (
  <section id="footer">
    <footer className="bao-llnstudio-footer container-fluid">
      <div className="bao-llnstudio-footer__wrapper">
        <a href="https://www.pelckmans.be/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/img/logo-pelckmans-footer-2021.svg" height="42" />
        </a>
        <a className="text-right bao-llnstudio-footer__wrapper--teacher" href="/leerkracht">
          Leerkrachtomgeving&nbsp;
          <i className="icon-bao-llnstudio-arrow-right" />
        </a>
      </div>
    </footer>
  </section>
);

export default Footer;
