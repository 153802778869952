import PropTypes from 'prop-types';

export default PropTypes.shape({
  externalMedia: PropTypes.object,
  id: PropTypes.string,
  kind: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  exerciseCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      difficulty: PropTypes.number,
    }),
  ),
});
