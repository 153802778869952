import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Title from '../../components/title';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import LessonList from './components/lesson-list';
import Exercise from '../exercise';

import propTypes from '../../core/prop-types';

import { fetchEntitiesIfRequired } from '../../actions/entities';

export class LessonsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exercise: null,
      nextExercise: null,
    };
  }

  componentDidMount() {
    const { fetchEntities } = this.props;
    fetchEntities();
  }

  render() {
    const { method, module, slugs, nodes, chapter, fixedHeight } = this.props;

    let startNextExercise;
    if (this.state.nextExercise) {
      startNextExercise = () => {
        const nextExercise = this.state.nextExercise;

        this.setState(
          () => ({ exercise: null, nextExercise: null }),
          () => this.setState({ exercise: nextExercise, nextExercise: null })
        );
      };
    }

    return (
      <div className="content-container">
        <Menu
          color={method.primaryColor}
          image={method.logo}
          methodId={method.id}
          moduleColor={module.primaryColor}
          years={module.years}
          modulesSlug={`/leerling/${slugs.publishingHouse}/${slugs.subject}/${slugs.method}`}
          chapterTitle={chapter.title}
          lesson={this.state.exercise}
          closeExercise={() => {
            this.setState({ exercise: null, nextExercise: null });
          }}
          nextExercise={startNextExercise}
          restartExercise={() => {
            let exercise;
            let nextExercise;

            // reset state and refill it
            // to simulate refresh
            this.setState(
              prevState => {
                exercise = prevState.exercise;
                nextExercise = prevState.nextExercise;
                return { exercise: null, nextExercise: null };
              },
              () => this.setState({ exercise, nextExercise })
            );
          }}
        />

        {!this.state.exercise && <Title text="Kies een les en oefen" color={method.primaryColor} />}

        {!this.state.exercise ? (
          <React.Fragment>
            <section id="content">
              <LessonList
                nodes={nodes}
                chapter={chapter}
                slugs={slugs}
                method={method}
                fixedHeight={fixedHeight}
                module={module}
                openExercise={(exercise, nextExercise) => {
                  this.setState({
                    exercise,
                    nextExercise,
                  });
                }}
              />
            </section>
            <Footer />
          </React.Fragment>
        ) : (
          <Exercise href={this.state.exercise.href} />
        )}
      </div>
    );
  }
}

LessonsPage.propTypes = {
  fetchEntities: PropTypes.func.isRequired,
  method: propTypes.method,
  module: propTypes.module,
  slugs: PropTypes.object,
  fixedHeight: PropTypes.bool,
  nodes: PropTypes.arrayOf(propTypes.node),
  chapter: propTypes.node,
};

LessonsPage.defaultProps = {
  method: {
    lessonDisplay: '',
  },
  module: {},
  slugs: {},
  fixedHeight: false,
  nodes: [],
  chapter: {},
};

const mapStateToProps = (state, props) => {
  const {
    match: { params },
  } = props;

  // As the module slug is garanteed unique in p,
  // we can simplify this routine by first looking for module
  const module = Object.values(state.entities.modules).find(m => m.slug === params.module);
  if (!module) return {};

  const method = Object.values(state.entities.methods).find(
    m => m.slug === params.method && m.modules.includes(module.id)
  );
  if (!method) return {};

  const subject = state.entities.subjects[method.subject];
  if (
    !subject ||
    subject.slug !== params.subject ||
    !subject.publishingHouse ||
    subject.publishingHouse.slug !== params.publishingHouse
  )
    return {};

  const toc = state.entities.tocs[module.toc] || { nodes: [] };

  const chapter =
    Object.values(state.entities.nodes).find(n => n.slug === params.node && toc.nodes.includes(n.id)) || {};

  let fixedHeight = false;
  const nodes = (chapter.nodes || []).map(n => {
    const node = Object.assign({}, state.entities.nodes[n]);

    const mLinks = Object.values(state.entities.mediaLinks).filter(m => (node.mediaLinks || []).includes(m.id));

    if (mLinks.length > 0) {
      fixedHeight = true;
      node.mediaLinks = mLinks;
    } else node.mediaLinks = null;

    return node;
  });

  return {
    method,
    module,
    slugs: {
      publishingHouse: params.publishingHouse,
      subject: params.subject,
      method: params.method,
      module: params.module,
    },
    nodes,
    chapter,
    fixedHeight,
  };
};

const mapDispatchToProps = dispatch => () => ({
  fetchEntities: () => dispatch(fetchEntitiesIfRequired()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LessonsPage);
