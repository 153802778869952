import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import ExerciseButton from './exercise-button';
import propTypes from '../core/prop-types';

const ExerciseButtonPanel = ({ mediaLinks, onMediaLinkClicked, lessonTitle, module }) => {
  if (!mediaLinks || mediaLinks.length === 0) return undefined;

  // enrich medialink with highestDifficulty
  const enrichedMediaLinks = mediaLinks
    .map(link => ({
      ...link,
      difficulty: (link.exerciseCategories || []).map(cat => cat.difficulty).sort()[0],
    }));

  const exercises = [
    enrichedMediaLinks.find(x => x.difficulty === 1),
    enrichedMediaLinks.find(x => x.difficulty === 2),
    enrichedMediaLinks.find(x => ![1, 2].includes(x.difficulty)),
  ]
    .filter(exercise => exercise)
    .splice(0, 2);

  return exercises.length > 0 &&
    (
      <div>
        {exercises
          .map(mediaLink => (
            <ExerciseButton
              key={mediaLink.id}
              difficulty={mediaLink.difficulty}
              onClick={(e) => {
                e.preventDefault();

                if (window.ga) {
                  ReactGA.event({
                    category: 'BAO - Exercises',
                    action: 'Open Exercise Series',
                    label: `${lessonTitle} - ${mediaLink.difficulty}`,
                    dimension1: module.name,
                  });
                }

                if (mediaLink.difficulty === 1) {
                  const mediaLinkWithDifficulty2 = exercises.find(x => x.difficulty === 2);
                  if (mediaLinkWithDifficulty2) return onMediaLinkClicked(mediaLink, mediaLinkWithDifficulty2);
                }
                return onMediaLinkClicked(mediaLink);
              }}
            />
          ))
        }
      </div >
    )
};

ExerciseButtonPanel.propTypes = {
  mediaLinks: PropTypes.arrayOf(propTypes.mediaLink),
  onMediaLinkClicked: PropTypes.func,
};

ExerciseButtonPanel.defaultProps = {
  mediaLinks: [],
  onMediaLinkClicked: () => { },
};

export default ExerciseButtonPanel;
