import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ExerciseButtonPanel from '../../../components/exercise-button-panel';
import propTypes from '../../../core/prop-types';

export const Image = styled.div`
  background: ${props => props.image && `url('${props.image}') center center no-repeat !important`};
`;

export const Tile = styled.div`
  .bao-llnstudio-chapter__tile {
    height: ${props => {
    const { description, image, mediaLinks } = props.fixedHeight || {};

    if (!image && !mediaLinks && description) return '122px';
    if (!mediaLinks && image) return '140px';
    if (!image && mediaLinks) return '144px';
    if (mediaLinks && image) return '184px';

    return '';
  }};

    cursor: ${props => props.noNestedNodes && 'default'};

    &:before {
      background-color: ${props => props.color && `${props.color} !important`};
    }

    &:hover {
      transform: ${props => props.noNestedNodes && 'none !important'};
    }

    &-body {
      &-info {
        &-title {
          color: ${props => props.color && `${props.color} !important`};
        }
      }
    }
  }
`;

const ChapterTile = props => {
  const { image, title, description, color, mediaLinks, slug, noNestedNodes, fixedHeight, openExercise, module } = props;

  return (
    <Tile
      noNestedNodes={noNestedNodes}
      fixedHeight={fixedHeight}
      color={color}
      className={image ? 'col-12 col-sm-12 col-md-6' : 'col-12 col-sm-12 col-md-6 col-lg-4'}
    >
      <Link to={!noNestedNodes ? slug : '#'} className="bao-llnstudio-chapter__tile">
        <div className="bao-llnstudio-chapter__tile-body">
          {image && <Image className="bao-llnstudio-chapter__tile-body-img" image={image} />}

          <div className="bao-llnstudio-chapter__tile-body-info">
            <div className="bao-llnstudio-chapter__tile-body-info-title">{title}</div>

            {description && <div className="bao-llnstudio-chapter__tile-body-info-segment">{description}</div>}
          </div>
        </div>

        {mediaLinks && (
          <div className="bao-llnstudio-chapter__tile-footer">
            <ExerciseButtonPanel
              mediaLinks={mediaLinks}
              onMediaLinkClicked={(...links) => {
                const exercises = (links || [])
                  .map(m => {
                    const { externalMedia: { href } } = m;
                    return { href, prefix: title, title: description };
                  });
                openExercise(exercises[0], exercises[1]);
              }}
              lessonTitle={title}
              module={module}
            />
          </div>
        )}
      </Link>
    </Tile>
  );
};

ChapterTile.propTypes = {
  noNestedNodes: PropTypes.bool.isRequired,
  fixedHeight: PropTypes.object.isRequired,
  slug: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  color: PropTypes.string,
  mediaLinks: PropTypes.arrayOf(propTypes.mediaLink),
  openExercise: PropTypes.func.isRequired,
  module: PropTypes.object,
};

ChapterTile.defaultProps = {
  slug: '/leerling',
  image: null,
  description: null,
  color: '#4DACC6',
  mediaLinks: null,
  module: {},
};

export default ChapterTile;
