import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ExerciseButtonPanel from '../../../components/exercise-button-panel';
import propTypes from '../../../core/prop-types';

export const Image = styled.div`
  background: ${props => props.image && `url('${props.image}') center center no-repeat !important`};
  background-color: ${props => props.color && `${props.color} !important`};
`;

export const Tile = styled.div`
  .bao-llnstudio-lesson__tile {
    min-height: ${props => props.fixedHeight && '184px'};
    &:before {
      background-color: ${props => props.color && `${props.color} !important`};
    }
    &-body {
      &-info {
        &-segment {
          color: ${props => props.color && `${props.color} !important`};
        }
      }
    }
  }
`;

const getClassNameForTileDisplay = display => {
  const tile = 'bao-llnstudio-lesson__tile';
  switch (display) {
    case 'big_number':
      return `${tile}--bignumber`;
    case 'big_title':
      return `${tile}--bigtitle`;
    case 'neutral':
      return `${tile}--neutral`;
    default:
      return ''
  }
};

const LessonTile = props => {
  const { display, image, prefix, title, color, mediaLinks, fixedHeight, openExercise, module } = props;

  return (
    <Tile fixedHeight={fixedHeight} color={color} className="col-12 col-sm-12 col-md-6">
      <div className={`bao-llnstudio-lesson__tile ${getClassNameForTileDisplay(display)}`}>
        <div className="bao-llnstudio-lesson__tile-body">
          <Image className="bao-llnstudio-lesson__tile-body-img" image={image} color={color} />

          <div className="bao-llnstudio-lesson__tile-body-info">
            {prefix && <div className="bao-llnstudio-lesson__tile-body-info-segment">{prefix}</div>}

            <div className="bao-llnstudio-lesson__tile-body-info-title">{title}</div>
          </div>
        </div>

        {mediaLinks && (
          <div className="bao-llnstudio-lesson__tile-footer">
            <ExerciseButtonPanel
              mediaLinks={mediaLinks}
              lessonTitle={title}
              module={module}
              onMediaLinkClicked={(...links) => {
                const exercises = (links || [])
                  .map(m => {
                    const { externalMedia: { href } } = m;
                    return { href, prefix, title };
                  });
                openExercise(exercises[0], exercises[1]);
              }}
            />
          </div>
        )}
      </div>
    </Tile>
  );
};

LessonTile.propTypes = {
  image: PropTypes.string,
  prefix: PropTypes.string,
  title: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  color: PropTypes.string,
  fixedHeight: PropTypes.bool.isRequired,
  mediaLinks: PropTypes.arrayOf(propTypes.mediaLink),
  openExercise: PropTypes.func.isRequired,
  module: PropTypes.object,
};

LessonTile.defaultProps = {
  image: null,
  prefix: null,
  color: '#4DACC6',
  mediaLinks: null,
  module: {},
};

export default LessonTile;
