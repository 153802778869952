import React from 'react';
import PropTypes from 'prop-types';

import propTypes from '../../../core/prop-types';
import MethodTile from './method-tile';

const MethodList = ({ methods, subjects }) => (
  <section id="methodpicker">
    <div className="bao-llnstudio-methodpicker">
      <div className="container">
        <div className="row">
          {methods.map(method => (
            <MethodTile
              key={method.id}
              method={method}
              subject={subjects[method.subject]}
            />
          ))}
        </div>
      </div>
    </div>
  </section>
);

MethodList.propTypes = {
  methods: PropTypes.arrayOf(propTypes.method),
  subjects: propTypes.subject,
};

MethodList.defaultProps = {
  methods: [],
  subjects: {},
};

export default MethodList;
